import React from 'react'

import {withRails} from '../../contexts/RailsContext'
import withSWR from '../../contexts/SwrContext'

// Chakra imports
import {Flex, useColorModeValue} from '@chakra-ui/react'

// Custom components
import AdminLayout from '../../layouts/Admin'
import {VSeparator} from '../../components/separator/Separator'
import AchieveQualifications from './components/AchieveQualifications'
import Report from '../../components/reports/Report'

export const CustomReportsScreen = () => {
  // Chakra Color Mode
  const paleGray = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')
  return (
    <AdminLayout>
      <Flex
        direction={{base: 'column', xl: 'row'}}
        pt={{base: '130px', md: '80px', xl: '80px'}}
      >
        <Report
          name={'Achieve Qualifications'}
          component={AchieveQualifications}
        />

        <VSeparator
          mx="20px"
          bg={paleGray}
          display={{base: 'none', xl: 'flex'}}
        />
      </Flex>
    </AdminLayout>
  )
}

CustomReportsScreen.propTypes = {}

export default withRails(withSWR(CustomReportsScreen))
