import React from 'react'
import PropTypes from 'prop-types'

// Custom components
import TableReport from '../../../components/reports/TableReport'

const RecentEnrollments = ({name}) => {
  const recentEnrollmentsDataColumns = [
    {
      header: 'Name',
      accessor: 'name',
    },
    {
      header: 'Customer Type',
      accessor: 'customer_type_description',
    },
    {
      header: 'Date Entered',
      accessor: 'created_date',
      is_date: true,
    },
    {
      header: 'Enroller ID',
      accessor: 'enroller_id',
    },
    {
      header: 'Enroller Name',
      accessor: 'enroller_name',
    },
    {
      header: 'Initial Order Total',
      accessor: 'initial_order_total',
    },
  ]

  return <TableReport name={name} dataColumns={recentEnrollmentsDataColumns} />
}

RecentEnrollments.propTypes = {
  name: PropTypes.string.isRequired,
}

export default RecentEnrollments
