// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import ReactComponent from '../react'

import DashboardScreen from '~/react/screens/DashboardScreen'
import OrdersScreen from '~/react/screens/OrdersScreen'
import CustomReportsScreen from '~/react/screens/CustomReportsScreen'
import AutoshipsScreen from '../react/screens/AutoshipsScreen'
import CommissionsScreen from '../react/screens/CommissionsScreen'
import KpiScreen from '../react/screens/KpiScreen'
import SignInScreen from '~/react/screens/SignInScreen'
import OrganisationProfileScreen from '~/react/screens/OrganisationProfileScreen'

ReactComponent.setup({
  DashboardScreen,
  OrdersScreen,
  CustomReportsScreen,
  AutoshipsScreen,
  CommissionsScreen,
  KpiScreen,
  SignInScreen,
  OrganisationProfileScreen,
})
